/* asv2 */
export const LANGUAGE_COOKIE_NAME = 'AC_LANG';
export const CURRENCY_COOKIE_NAME = 'AC_CURR';
export const DEVICE_COOKIE_NAME = 'DS';

/* Cookie expiries */
export const COOKIE_EXPIRY_SECONDS = 34560000;
export const COOKIE_EXPIRY_ONE_DAY_IN_MS = 86400000;
export const COOKIE_EXPIRY_DAYS = 400;

/* Experiments */
export const EXPERIMENT_VARIANTS_HEADER = 'as-experiment-variants';
export const EXPERIMENTS_OVERRIDE_COOKIE = 'as-experiment-override';
